<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.update_goods_price") }}
      <v-spacer></v-spacer>
      <div class="text-right">
        <v-btn small color="warning" @click="downloadExcel" class="mr-2">
          <v-icon>mdi-download</v-icon> {{ $t("labels.goods") }}
        </v-btn>
        <!-- <a
          class="mr-1 v-btn v-btn--outlined theme--light v-size--small default"
          :href="templateLink"
          target="_blank"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.sample_file") }}
        </a> -->
        <v-btn
          small
          color="info"
          @click="$refs.inputUploadFile.click()"
          class="mr-2"
        >
          <v-icon>mdi-upload</v-icon> {{ $t("labels.upload") }}
        </v-btn>
        <input
          type="file"
          ref="inputUploadFile"
          accept=".xlsx"
          @change="onInputFileChange"
          class="d-none"
        />

        <v-btn
          small
          color="success"
          @click="updateGoodsCategory"
          class="mr-2"
          :disabled="isDisabledBtnSubmit"
        >
          <v-icon>mdi-check</v-icon> {{ $t("labels.update") }}
        </v-btn>

        <v-btn small color="error" @click="closeDialog">
          {{ $t("labels.close") }}
        </v-btn>
      </div>
    </v-card-title>

    <v-card-text>
      <div>
        <v-simple-table
          fixed-header
          height="calc(100vh - 265px)"
          class="table-padding-2"
        >
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.barcode") }}
                </th>
                <th
                  role="columnheader"
                  class="text-center"
                  v-if="customer.id_company_type == 2"
                >
                  {{ $t("labels.vat") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.from_date") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.price") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.wholesale_price") }}
                </th>
                <th
                  role="columnheader"
                  class="text-center"
                  v-if="checkPermission(['administration_goods_insurance'])"
                >
                  {{ $t("labels.insurance_value") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.goods_name") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.goods_description") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.size") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="`c_${index}`"
                class="text-center"
              >
                <td>
                  {{ item.customer_goods_barcode }}
                </td>
                <td v-if="customer.id_company_type == 2" class="error--text">
                  {{ item.vat }}
                </td>
                <td class="error--text">
                  {{ item.from_date }}
                </td>
                <td class="error--text">
                  {{ item.price ? formatNumber(item.price) : "" }}
                </td>
                <td class="error--text">
                  {{
                    item.wholesale_price
                      ? formatNumber(item.wholesale_price)
                      : ""
                  }}
                </td>
                <td
                  class="error--text"
                  v-if="checkPermission(['administration_goods_insurance'])"
                >
                  {{
                    item.insurance_value
                      ? formatNumber(item.insurance_value)
                      : ""
                  }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td>
                  {{ item.size }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment";
import { read, utils } from "xlsx";

export default {
  name: "UpdatePriceExcel",
  data: () => ({
    isLoading: false,
    customer: {},
    items: [],
    errRows: [],
    categories: [],
  }),
  computed: {
    isDisabledBtnSubmit() {
      return this.items.length === 0 || this.errRows.length > 0;
    },
    templateLink() {
      if (this.customer.id_company_type == 2) {
        return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_gia_ban_co_vat.xlsx`;
      }
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_gia_ban_khong_vat.xlsx`;
    },
  },
  mounted() {
    const { customer } = window.me;
    this.customer = { ...customer };
  },
  methods: {
    async onInputFileChange(e) {
      this.errRows = [];
      this.errTexts = [];
      const files = e.target.files;

      const mappingFields = {
        Barcode: "customer_goods_barcode",
        "Tên hàngProduct Name": "name",
        "Đặc tảDescription": "description",
        Size: "size",
        VAT: "vat_old",
        "Giá lẻRetail Price": "price_old",
        "Giá buônWholesale Price": "wholesale_price_old",
        "Giá bảo hiểmInsurance Quotes": "insurance_value_old",
        "Từ ngàyFrom": "from_date",
        VAT_1: "vat",
        "Giá lẻRetail Price_1": "price",
        "Giá buônWholesale Price_1": "wholesale_price",
        "Giá bảo hiểmInsurance Quotes_1": "insurance_value",
      };

      if (files && files[0]) {
        const f = files[0];
        this.file = f;
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          worksheet["!ref"] = worksheet["!ref"].replace("A1:", "A2:");
          let values = utils.sheet_to_json(worksheet);

          let items = [...values].map((v) => {
            const item = {};
            Object.keys(v).forEach((i) => {
              const mapkey = i.replace(/\r?\n|\r/g, "");
              if (mappingFields[mapkey] == "from_date") {
                item[mappingFields[mapkey]] = moment(
                  this.excelDateToJSDate(v[i])
                ).format("YYYY-MM-DD");
              } else {
                item[mappingFields[mapkey]] = `${v[i]}`.trim();
              }
            });
            return item;
          });

          items = [...items].filter(
            (item) =>
              item.vat ||
              item.price ||
              item.wholesale_price ||
              item.insurance_value
          );

          items = [...items].map((item) => {
            return {
              ...item,
              from_date: item.from_date,
              vat: item.vat || item.vat_old,
              price: item.price || item.price_old,
              wholesale_price: item.wholesale_price || item.wholesale_price_old,
              insurance_value: item.insurance_value || item.insurance_value_old,
            };
          });
          await this.validateGoodsCategory(items);
        };
        await reader.readAsArrayBuffer(f);
      }
    },

    async validateGoodsCategory(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
        return false;
      }
      /* if (items.length > 1000) {
        this.$vToastify.error(
          this.$t("messages.update_goods_price_max_1000_rows")
        );
        return false;
      } */

      const today = moment().format("YYYY-MM-DD");
      const wrongDateItems = [...items]
        .filter((item) => !item.from_date || item.from_date < today)
        .map((item) => item.customer_goods_barcode);
      if (wrongDateItems && wrongDateItems.length > 0) {
        this.$vToastify.error(
          this.$t("messages.wrong_date_barcodes", {
            barcodes: wrongDateItems.join("; "),
          })
        );
        return false;
      }

      const emptyPrice = [...items]
        .filter(
          (item) =>
            !item.price && !item.wholesale_price && !item.insurance_value
        )
        .map((item) => item.customer_goods_barcode);
      if (emptyPrice && emptyPrice.length > 0) {
        this.$vToastify.error(
          this.$t("messages.empty_price_barcodes", {
            barcodes: emptyPrice.join("; "),
          })
        );
        return false;
      }

      const duplicateBarcodes = [];
      const noDuplicateBarcodes = [];
      items.forEach((i) => {
        const nd = [...noDuplicateBarcodes].find(
          (n) => n == i.customer_goods_barcode
        );
        if (!nd) {
          noDuplicateBarcodes.push(i.customer_goods_barcode);
        } else {
          duplicateBarcodes.push(i.customer_goods_barcode);
        }
      });
      if (duplicateBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateBarcodes.join("; "),
          })
        );
        return false;
      }

      /* const barcodes = [...items]
        .filter((i) => !!i.customer_goods_barcode)
        .map((i) => i.customer_goods_barcode);
      const { data } = await httpClient.post("/get-goods-by-barcodes", {
        barcodes,
      });

      this.items = [...items].map((item) => {
        const row = [...data].find(
          (d) => d.customer_goods_barcode == item.customer_goods_barcode
        );
        return { ...row, ...item };
      }); */

      this.items = [...items];
    },

    async updateGoodsCategory() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-update-price-list", {
          items: this.items,
        });
        this.items = [];
        this.$vToastify.success(this.$t("messages.update_success"));
        this.isLoading = false;
        this.refreshData();
        this.closeDialog();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    closeDialog() {
      this.$emit("cancel");
    },

    refreshData() {
      this.$emit("refreshData");
    },

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-hang-hoa-gia-ban.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-list-export-for-price",
          {},
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
